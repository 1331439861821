import MuiCheckbox from "@mui/material/Checkbox";
import Colors from "../Util/Constants/Colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { Tooltip } from "@mui/material";

interface CheckboxProps {
  label: string;
  value?: boolean;
  error?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  onChange: (newValue: boolean) => void;
}

export default function Checkbox(props: CheckboxProps) {
  const textColor = props.error ? "red" : Colors.black;
  const boxColor = props.error ? "error" : "primary";
  const labelStyle = {
    color: textColor,
  };

  const component = (
    <FormGroup row>
      <FormControlLabel
        control={
          <MuiCheckbox
            disabled={props.disabled}
            checked={props.value}
            onChange={(_, isInputChecked) => props.onChange(isInputChecked)}
            value={props.label}
            color={boxColor}
          />
        }
        label={<span style={labelStyle}>{props.label}</span>}
      />
    </FormGroup>
  );

  return props.disabled && props.disabledMessage ? (
    <Tooltip title={props.disabledMessage}>{component}</Tooltip>
  ) : (
    component
  );
}
